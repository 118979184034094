import React, { useEffect, useState } from 'react'
import { HostQuery, RegisterForCampaignMutation } from './generated/graphql'
import sdk from './sdk'
import FeedForm from './components/FeedForm/FeedForm'
import styles from './App.module.css'
import CollapsibleContainer from './components/CollapsibleContainer/CollapsibleContainer'
import FeedAndStories from './components/StoriesAndFeed'
import loadingAnimation from './icons/loading.gif'
import successAnimation from './icons/success.gif'
import { ReactComponent as CancelIcon } from './icons/cancel-minor.svg'
import { ReactComponent as LockIcon } from './icons/lock.svg'
import Skeleton from './components/Skeleton'
import { ReactComponent as ErrorCircleIcon } from './icons/x-circle.svg'
import CompleteForm from './components/CompleteForm'

const programHostError = 'This form is not available at this moment, please try again later.'

type HostError = {
  message: string
}
type HostErrorType = {
  response: {
    error?: string
    errors?: HostError[]
  }
}

type ContainerType = 'feed' | 'storiesAndFeed'

type ContainerState = {
  type: ContainerType | null
  highlight: boolean
}

const defaultContainerState = {
  type: null,
  highlight: false,
}

function App(): React.ReactElement {
  const [programHostLcId, setProgramHostLcId] = useState<string | null>(null)
  const [buttonText, setButtonText] = useState<string | null>(null)
  useEffect(
    function () {
      if (window.location.pathname.startsWith('/campaign/')) {
        const pathChunks = window.location.pathname.split('/')
        const id = pathChunks.length >= 3 ? pathChunks[2] : null
        const params = new URLSearchParams(window.location.search)
        const btnText = params.get('buttonText') || null
        if (id) {
          setProgramHostLcId(id)
        }
        if (btnText) {
          setButtonText(btnText)
        }
      }
    },
    [setProgramHostLcId],
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hostData, setHostData] = useState<HostQuery | null>(null)
  const [hostError, setHostError] = useState<HostErrorType | null>(null)
  useEffect(
    function () {
      if (programHostLcId) {
        setIsLoading(true)
        sdk
          .Host({ lcId: programHostLcId })
          .then(setHostData)
          .catch(setHostError)
          .finally(() => setIsLoading(false))
      }
    },
    [setHostData, programHostLcId],
  )

  const [containerState, setContainerState] = useState<ContainerState>(defaultContainerState)
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false)
  const [ambassadorStorefrontUrls, setAmbassadorStorefrontUrls] = useState<string[] | null>(null)

  function handleCloseModal(): void {
    if (window.parent && window.parent !== window) {
      window.parent.postMessage('lc-close-modal', '*')
    }
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage('lc-close-modal', '*')
    }
  }

  function handleFbErrorState(): void {
    setContainerState({ ...containerState, highlight: true })
  }

  function handleFormComplete(data: RegisterForCampaignMutation): void {
    setAmbassadorStorefrontUrls(
      data?.registerCampaignParticipant?.participants?.map((participant) => participant.storefrontUrl || '') || null,
    )
    if (window.parent && window.parent !== window) {
      window.parent.postMessage('lc-signed-up', '*')
    }
    setIsFormComplete(true)
  }

  function handleContainerClick(containerType: ContainerType): void {
    const newType = containerState.type === containerType ? null : containerType
    const feedIsHighlighted = containerType === 'feed' && containerState.highlight

    let newState = {
      ...containerState,
      type: newType,
    }

    if (feedIsHighlighted) {
      newState = {
        ...newState,
        highlight: false,
      }
    }
    setContainerState(newState)
  }

  let successCopy = 'Check your email for updates!'

  if (isFormComplete && containerState.type === 'storiesAndFeed') {
    successCopy += 'Check back here in 60 days to re-authenticate your connection to Instagram.'
  }

  const requiredUsernames: ('igUsername' | 'ttUsername')[] = []
  if (hostData?.programHost?.igRequired) {
    requiredUsernames.push('igUsername')
  }
  if (hostData?.programHost?.ttRequired) {
    requiredUsernames.push('ttUsername')
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        {hostError?.response ? (
          <div className={styles.hostError}>
            <div className={styles.hostErrorIcon}>
              <ErrorCircleIcon width={20} height={20} onClick={handleCloseModal} />
            </div>
            <div className={styles.hostErrorColumn}>
              <div className={styles.connectionErrorTitle}>Oops! That didn’t work</div>
              <div>{programHostError}</div>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.closeButtonContainer}>
              <CancelIcon className={styles.cancelIcon} onClick={handleCloseModal} />
            </div>
            {hostData?.programHost?.logoUrl ? (
              <div>
                <img
                  className={styles.hostLogo}
                  alt={hostData?.programHost?.program?.name || ''}
                  src={`${hostData.programHost.logoUrl}`}
                />
                <h1>{hostData?.programHost?.program?.name || 'Ambassador Application'}</h1>
                {isFormComplete && <span>{successCopy}</span>}
              </div>
            ) : (
              <Skeleton />
            )}
          </>
        )}
      </div>
      <div className={styles.modalBody}>
        {isLoading && (
          <div>
            <div className={styles.loadingContainer}>
              <img src={loadingAnimation} className={styles.animationGif} alt="loading" />
            </div>
          </div>
        )}
        {isFormComplete ? (
          <CompleteForm
            successAnimation={successAnimation}
            hostData={hostData}
            handleCloseModal={handleCloseModal}
            ambassadorStorefrontUrls={ambassadorStorefrontUrls}
          />
        ) : (
          <>
            {!isLoading && !hostError && (
              <>
                {!hostData?.programHost?.allowFbAuth ? (
                  <FeedForm
                    submitButtonText={buttonText}
                    programHostLcId={programHostLcId}
                    handleFormComplete={handleFormComplete}
                    customFields={hostData?.programHost?.customFields}
                    platforms={hostData?.programHost?.socialPlatforms}
                    requiredUsernames={requiredUsernames}
                  />
                ) : (
                  <>
                    <CollapsibleContainer
                      title="Stories + Feed"
                      isOpen={containerState.type === 'storiesAndFeed'}
                      onContainerClick={() => handleContainerClick('storiesAndFeed')}
                    >
                      <FeedAndStories
                        handleFbErrorState={handleFbErrorState}
                        programHostLcId={programHostLcId}
                        handleFormComplete={handleFormComplete}
                        customFields={hostData?.programHost?.customFields}
                      />
                    </CollapsibleContainer>
                    <CollapsibleContainer
                      highlight={containerState.highlight}
                      title="Feed Only"
                      isOpen={containerState.type === 'feed'}
                      onContainerClick={() => handleContainerClick('feed')}
                    >
                      <FeedForm
                        submitButtonText={buttonText}
                        programHostLcId={programHostLcId}
                        handleFormComplete={handleFormComplete}
                        customFields={hostData?.programHost?.customFields}
                        platforms={hostData?.programHost?.socialPlatforms}
                        requiredUsernames={requiredUsernames}
                      />
                    </CollapsibleContainer>
                  </>
                )}
              </>
            )}
            <div className={styles.modalFooter}>
              <div className={styles.poweredByLoudcrowd}>
                <LockIcon width={16} />
                <p>
                  Powered by{' '}
                  <span className={styles.poweredByLoudcrowdLoudcrowd}>
                    <a
                      href="https://www.loudcrowd.com"
                      target="_blank"
                      rel="noreferrer"
                      className={styles.loudcrowdLink}
                    >
                      LoudCrowd
                    </a>
                  </span>
                </p>
              </div>
              <div>
                <a href="https://www.loudcrowd.com/privacy-policy-dpf" target="_blank" rel="noreferrer">
                  LoudCrowd Privacy Policy
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default App
